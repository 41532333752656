import React from "react";
import LabelComponent from "./label/LabelComponent";

function UploadFile({ label, buttonLabel, id, accept, error, file, setFile }) {
  const handleIconClick = () => {
    setFile(null);
  };
  return (
    <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center my-4">
      <LabelComponent label={label} id={id} error={error} />

      <div className="flex flex-wrap items-center gap-4">
        <input
          type="file"
          id={id}
          accept={accept}
          hidden
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
        />
        <label
          htmlFor={id}
          className="bg-[#C5C5C5] px-4 py-2 rounded-md cursor-pointer"
        >
          <i className="fa-solid fa-paperclip mr-1"></i> {buttonLabel}
        </label>
        {file && (
          <p>
            {file.name}{" "}
            <i
              className="fa-solid fa-circle-xmark cursor-pointer pl-1 md:pl-2"
              onClick={handleIconClick}
            ></i>
          </p>
        )}
      </div>
    </div>
  );
}

export default UploadFile;
