import { get } from "lodash";
import toast from "react-hot-toast";
import axios from "./axios";
import { errorMessageHandler, tripFormPayload } from "./helpers";

export const submitTripForm = async (payload, insideQatar) => {
  const formatedData = tripFormPayload(payload, insideQatar);
  try {
    const { data: responseData } = await axios.post(
      "/api/trip/create",
      formatedData,
      {
        rawRequest: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (get(responseData, "status", false)) {
      if (responseData?.data) {
        return responseData.data;
      }
    } else {
      toast.error(errorMessageHandler(responseData));
      return false;
    }
  } catch (error) {
    if (error.code !== "ERR_CANCELED") {
      console.log("error", error);
      toast.error("Something went wrong!");
      return false;
    }
  }
};

export const schoolListing = async (signal) => {
  try {
    const { data: responseData } = await axios.post(
      "/api/school/list",
      {},
      {
        ...(signal && { signal }),
      }
    );
    if (get(responseData, "status", false)) {
      if (responseData?.data) {
        return responseData.data.map((item) => ({
          id: item.id,
          label: item.name_english ? item.name_english : item.name_arabic,
          arabicLabel: item.name_arabic ? item.name_arabic : item.name_english,
          phone: item.phone ? item.phone : "-",
          email: item.email ?? "",
          location: item.location ? item.location : "-",
          kind: item.kind ?? "",
          specialist: item.new_specialist
            ? item.new_specialist
            : item.specialist,
        }));
      }
    } else {
      toast.error(errorMessageHandler(responseData));
      return false;
    }
  } catch (error) {
    if (error.code !== "ERR_CANCELED") {
      console.log("error", error);
      toast.error("Something went wrong!");
      return false;
    }
  }
};

export const locationListing = async (signal) => {
  try {
    const { data: responseData } = await axios.post(
      "/api/location/list",
      {},
      {
        ...(signal && { signal }),
      }
    );
    if (get(responseData, "status", false)) {
      if (responseData?.data) {
        return responseData.data.map((item) => ({
          id: item.id,
          label: item.address,
        }));
      }
    } else {
      toast.error(errorMessageHandler(responseData));
      return false;
    }
  } catch (error) {
    if (error.code !== "ERR_CANCELED") {
      console.log("error", error);
      toast.error("Something went wrong!");
      return false;
    }
  }
};
