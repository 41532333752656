import React from "react";
import LabelComponent from "../label/LabelComponent";

const InputComponent = ({
  name,
  label,
  id,
  placeholder,
  type,
  onChange,
  error,
}) => {
  const today = new Date().toISOString().slice(0, 10);

  return (
    <>
      <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center my-4">
        <LabelComponent label={label} id={id} error={error} />
        <input
          id={id}
          name={name}
          type={type}
          className="w-full bg-white border border-[#4A4A4A] focus:ring-black focus:border-black text-gray-900 text-sm rounded-lg block p-2.5"
          placeholder={placeholder}
          onChange={onChange}
          min={type === "date" ? today : undefined}
        />
      </div>
    </>
  );
};

export default InputComponent;
