import { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "./i18n";
import Home from "./pages/Home";
import QatarTrip from "./pages/QatarTrip";
import Page404 from "./pages/error/Page404";

const router = createBrowserRouter([
  { index: true, element: <Home /> },
  { path: "/inside-qatar", element: <QatarTrip /> },
  { path: "/outside-qatar", element: <QatarTrip /> },
  { path: "/404", element: <Page404 /> },
  { path: "*", element: <Navigate to="/404" replace /> },
]);

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Toaster position="top-center" />
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
