import Main from "../components/Main";
import LanguageSelector from "../components/LanguageSelector";

const Home = () => {
  return (
    <div className="relative container m-auto">
      <div className="absolute top-5 right-5">
        <LanguageSelector />
      </div>
      <Main />
    </div>
  );
};

export default Home;
