import React from "react";
import AutoCompleteComponent from "./autoComplete/AutoCompleteComponent";
import LabelComponent from "./label/LabelComponent";

const Dropdown = ({
  name,
  label,
  id,
  placeholder,
  value,
  onChange,
  error,
  options,
}) => (
  <div className="flex flex-col items-start sm:flex-row sm:items-center my-4">
    <LabelComponent label={label} id={id} error={error} />
    <AutoCompleteComponent
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      options={options}
      onChange={onChange}
    />
  </div>
);

export default Dropdown;
