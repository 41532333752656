import { useTranslation } from "react-i18next";
import { optimizeNumber } from "../utils/helpers";
import LabelComponent from "./label/LabelComponent";

function SchoolBusInput({
  label,
  id,
  placeholder,
  type,
  buses,
  formik,
  error,
}) {
  const { t } = useTranslation();

  const handleAddBusSize = () => {
    formik.setFieldValue("buses", [...buses, { value: "", QR: "" }]);
  };

  const handleDeleteBusSize = (index) => {
    const newBusSizes = [...buses];
    newBusSizes.splice(index, 1);
    formik.setFieldValue("buses", newBusSizes);
  };

  return (
    <div className="flex flex-col items-start sm:flex-row my-4">
      <LabelComponent
        label={label}
        id={id}
        error={error}
        styles={"sm:mt-2.5"}
      />

      <div className="flex flex-col items-start w-full gap-4">
        {buses?.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between gap-x-6 w-full md:w-8/12">
            <p className="text-[#4A4A4A] font-[600] text-[15px] min-w-16">
              {t("label.busCount")} {optimizeNumber(index + 1)}
            </p>
            <p className="text-[#4A4A4A] font-[600] text-[15px] min-w-16">
              {t("label.busSize")}
            </p>
            <input
              type={type}
              value={item.value}
              className=" bg-white border border-[#4A4A4A] focus:ring-black focus:border-black text-gray-900 text-sm rounded-lg block p-2.5"
              placeholder={placeholder}
              onChange={(e) => {
                const clone = [...buses];
                clone[index] = { ...clone[index], value: e.target.value };
                formik.setFieldValue("buses", clone);
              }}
            />
            {index > 0 ? (
              <button
                type="button"
                className="text-rose-500"
                onClick={() => handleDeleteBusSize(index)}>
                <i className="fa-solid fa-xmark px-3 py-2.5"></i>
              </button>
            ) : (
              <button
                type="button"
                className="disabled:opacity-0"
                disabled={buses?.length > 4}
                onClick={handleAddBusSize}>
                <i className="fa-solid fa-plus bg-[#C5C5C5] px-3 py-2.5 rounded-md"></i>
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SchoolBusInput;
