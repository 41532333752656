import React from "react";
import LabelComponent from "./label/LabelComponent";

function TermsAndConditions({ name, text, value, onChange, onBlur, error }) {
  return (
    <div className="flex items-center">
      <input
        name={name}
        id={name}
        type="checkbox"
        checked={value}
        onBlur={onBlur}
        onChange={onChange}
        className="w-4 h-4 mr-2 accent-[#333333]  bg-gray-100 border-gray-300 focus:ring-black focus:border-black rounded"
      />
      <LabelComponent
        label={text}
        styles={`!whitespace-normal`}
        id={name}
        error={error}
      />
    </div>
  );
}

export default TermsAndConditions;
