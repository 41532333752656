export const languages = [
  { key: "en", name: "English" },
  { key: "ar", name: "Arabic" },
];

export const options = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
];

export const tripPurposeEnums = [
  { value: "science", label: "Science" },
  { value: "religion", label: "Religion" },
  { value: "fun", label: "Fun" },
  { value: "social", label: "Social" },
  { value: "health", label: "Health" },
  { value: "art", label: "Art" },
  { value: "culture", label: "Culture" },
];

export const schoolSectionEnum = {
  1: "kindergarten",
  2: "primary",
  3: "preparatory",
  4: "secondary",
};
