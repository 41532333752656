import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import Header from "../components/Header";
import TripForm from "../components/TripForm";
import {
  locationListing,
  schoolListing,
  submitTripForm,
} from "../utils/apiCalls";
import { sumOfNumbers } from "../utils/helpers";

const QatarTrip = () => {
  const [schoolDropDown, setSchoolDropDown] = useState([]);
  const [locationDropDown, setLocationDropDown] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const location = useLocation();
  const insideQatar = location.pathname.includes("inside-qatar");

  const validationSchema = useMemo(
    () =>
      Yup.object({
        school: Yup.object().required("Required"),
        principal: Yup.string().required("Required"),
        title: Yup.string().required("Required"),
        location: Yup.object()
          .required("Required")
          .test((value) => (insideQatar ? value.id : value.description)),
        date: Yup.string().required("Required"),
        nature: Yup.string().required("Required"),
        purpose: Yup.object().required("Required"),
        schoolSection: Yup.string().required("Required"),
        maleStudents: Yup.number()
          .positive()
          .test(
            (value, { parent }) => (value || parent.femaleStudents) && true
          ),
        femaleStudents: Yup.number()
          .positive()
          .test((value, { parent }) => (value || parent.maleStudents) && true),
        maleSupervisors: Yup.number()
          .positive()
          .test(
            (value, { parent }) => (value || parent.femaleSupervisors) && true
          ),
        femaleSupervisors: Yup.number()
          .positive()
          .test(
            (value, { parent }) => (value || parent.maleSupervisors) && true
          ),
        ratioError: Yup.string().when(
          [
            "maleStudents",
            "femaleStudents",
            "maleSupervisors",
            "femaleSupervisors",
            "schoolSection",
          ],
          {
            is: (
              maleStudents,
              femaleStudents,
              maleSupervisors,
              femaleSupervisors,
              schoolSection
            ) => {
              const totalStudents = sumOfNumbers(maleStudents, femaleStudents);
              const totalSupervisors = sumOfNumbers(
                maleSupervisors,
                femaleSupervisors
              );
              let supervisorsRatio;
              const parsedSelectedSchool = parseInt(schoolSection);
              if (parsedSelectedSchool === 3 || parsedSelectedSchool === 4) {
                supervisorsRatio = totalStudents / 13;
              } else {
                supervisorsRatio = totalStudents / 10;
              }
              if (
                Math.ceil(supervisorsRatio) !== totalSupervisors &&
                parsedSelectedSchool
              )
                return true;
              else return false;
            },
            then: () => Yup.string().required("Required"),
            otherwise: () => Yup.string().optional(),
          }
        ),
        buses: Yup.array()
          .of(
            Yup.object({
              value: Yup.number().positive().required("Value is required"),
              // QR: Yup.string().when("type", {
              //   is: (type) => {
              //     console.log(type);
              //     return type === "1";
              //   },
              //   then: () => Yup.string().required("QR is required"),
              //   otherwise: () => Yup.string(),
              // }),
            })
          )
          .required("Buses array is required"),

        type: Yup.string().required("Required"),
        goal: Yup.string().required("Required"),
        checkOne: Yup.bool().oneOf([true], "Field must be checked"),
        checkTwo: Yup.bool().oneOf([true], "Field must be checked"),
        checkThree: Yup.bool().oneOf([true], "Field must be checked"),
        checkFour: Yup.bool().oneOf([true], "Field must be checked"),
        checkFive: Yup.bool().oneOf([true], "Field must be checked"),
        qoutationDocument: Yup.mixed().required("Required"),
        principalSignature: Yup.mixed().required("Required"),
        language: Yup.string(),
        natureOfFee: Yup.object(),
      }),
    [insideQatar]
  );

  const handleSubmit = async (values) => {
    const response = await submitTripForm(values, insideQatar);
    if (response) {
      formik.resetForm();
      setFormSubmitted(true);
      setTimeout(() => {
        setFormSubmitted(false);
      }, 5000);
    }
  };

  const formik = useFormik({
    initialValues: {
      school: null,
      principal: "",
      title: "",
      location: null,
      date: "",
      nature: "0",
      purpose: null,
      schoolSection: "",
      maleStudents: "",
      femaleStudents: "",
      maleSupervisors: "",
      femaleSupervisors: "",
      buses: [{ value: "", QR: "" }],
      ratioError: "",
      type: "0",
      goal: "",
      checkOne: false,
      checkTwo: false,
      checkThree: false,
      checkFour: false,
      checkFive: false,
      qoutationDocument: null,
      principalSignature: null,
      language: localStorage.getItem("language") ?? "",
      natureOfFee: {
        meal: {
          checked: true,
          QR: "",
        },
        ticket: {
          checked: false,
          QR: "",
        },
      },
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const response = await schoolListing(controller.signal);
      if (response) setSchoolDropDown(response);
    })();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (!insideQatar) return;
    (async () => {
      const response = await locationListing(controller.signal);
      if (response) setLocationDropDown(response);
    })();
    return () => controller.abort();
  }, [insideQatar]);

  return (
    <form
      className="pb-52"
      onSubmit={formik.handleSubmit}
      // dir={formik.values.language === "ar" ? "rtl" : "ltr"}
    >
      <Header
        loader={formik.isSubmitting}
        formik={formik}
        formSubmitted={formSubmitted}
        insideQatar={insideQatar}
      />
      <TripForm
        formik={formik}
        schoolDropDown={schoolDropDown}
        locationDropDown={locationDropDown}
        insideQatar={insideQatar}
      />
    </form>
  );
};

export default QatarTrip;
