import React from "react";

const Mailto = ({ email, subject = "", body = "", children }) => {
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

  return (
    <a
      href={`mailto:${email}${params}`}
      className="transition duration-300 ease-in-out hover:text-[#2E76EA]  cursor-pointer "
    >
      {children}
    </a>
  );
};

export default Mailto;
