import React from "react";
import "./label.css";

function LabelComponent({ label, id, styles, error }) {
  return (
    <label
      htmlFor={id}
      className={`label ${styles ?? ""} ${error && "error-message"}`}
    >
      {label}
      {error && " *"}
    </label>
  );
}

export default LabelComponent;
