import React from "react";
import { useTranslation } from "react-i18next";
import { tripPurposeEnums } from "../utils/constants";
import { sumOfNumbers } from "../utils/helpers";
import Dropdown from "./Dropdown";
import NatureOfFee from "./NatureOfFee";
import RadioInput from "./RadioInput";
import SchoolBusInput from "./SchoolBusInput";
import SchoolSection from "./SchoolSection";
import SelectSchool from "./SelectSchool";
import TermsAndConditions from "./TermsAndConditions";
import UploadFile from "./UploadFile";
import RatioAlert from "./alert/RatioAlert";
import InputComponent from "./input/InputComponent";
import LabelComponent from "./label/LabelComponent";

function TripForm({ formik, schoolDropDown, locationDropDown, insideQatar }) {
  const { t } = useTranslation();

  return (
    <div id="form">
      <SelectSchool
        name="school"
        id="school"
        formik={formik}
        placeholder={t("placeholder.school")}
        options={schoolDropDown}
        error={formik.touched.school && formik.errors.school}
      />

      <div className="divide-y container mx-auto">
        <div className="pl-8 py-4 w-full sm:w-8/12 md:w-6/12">
          <InputComponent
            name="principal"
            label={t("label.principal")}
            type="text"
            id="principal_name"
            placeholder={t("placeholder.principal")}
            value={formik.values.principal}
            onBlur={formik.onBlur}
            error={formik.touched.principal && formik.errors.principal}
            onChange={formik.handleChange}
          />
        </div>

        <div className="pl-8 py-4">
          <div className="w-full sm:w-8/12 md:w-10/12">
            <InputComponent
              name="title"
              label={t("label.title")}
              type="text"
              id="trip_title"
              placeholder={t("placeholder.title")}
              value={formik.values.title}
              onBlur={formik.onBlur}
              error={formik.touched.title && formik.errors.title}
              onChange={formik.handleChange}
            />
            {insideQatar ? (
              <Dropdown
                name="location"
                label={t("label.location")}
                id="location"
                placeholder={t("placeholder.location")}
                options={locationDropDown}
                value={formik.values.location}
                onBlur={formik.onBlur}
                error={formik.touched.location && formik.errors.location}
                onChange={(e) => formik.setFieldValue("location", e)}
              />
            ) : (
              <InputComponent
                name="location"
                label={t("label.location")}
                type="text"
                id="trip_title"
                placeholder={t("placeholder.location")}
                value={formik.values.location}
                onBlur={formik.onBlur}
                error={formik.touched.location && formik.errors.location}
                onChange={(e) =>
                  formik.setFieldValue("location", {
                    description: e.target.value,
                  })
                }
              />
            )}
          </div>
          <div className="w-full flex flex-wrap flex-col sm:flex-row items-start sm:items-center gap-x-20">
            <div className="w-full sm:w-8/12 md:w-6/12 lg:w-5/12">
              <InputComponent
                name="date"
                label={t("label.date")}
                type="date"
                id="trip-date"
                value={formik.values.date}
                onBlur={formik.onBlur}
                error={formik.touched.date && formik.errors.date}
                onChange={formik.handleChange}
              />
            </div>
            <RadioInput
              name="nature"
              label={t("label.nature")}
              radioLabel1={t("label.natureActivity")}
              radioLabel2={t("label.natureTrip")}
              id1="activity"
              id2="trip"
              value={formik.values.nature}
              onBlur={formik.onBlur}
              error={formik.touched.nature && formik.errors.nature}
              onChange={(value) => formik.setFieldValue("nature", value)}
            />
          </div>
          <div className="flex flex-col w-full sm:w-8/12 md:w-6/12 lg:w-5/12">
            <Dropdown
              name="purpose"
              label={t("label.purpose")}
              id="purpose"
              placeholder={t("placeholder.purpose")}
              value={formik.values.purpose}
              options={tripPurposeEnums}
              onBlur={formik.onBlur}
              error={formik.touched.purpose && formik.errors.purpose}
              onChange={(e) => formik.setFieldValue("purpose", e)}
            />
          </div>
          <div className="w-full">
            <SchoolSection
              id="schoolSection"
              name="schoolSection"
              label={t("label.schoolSection")}
              onBlur={formik.onBlur}
              error={
                formik.touched.schoolSection && formik.errors.schoolSection
              }
              value={formik.values.schoolSection}
              school={formik.values.school}
              onChange={(value) => formik.setFieldValue("schoolSection", value)}
            />
          </div>

          <div className="flex flex-wrap justify-start flex-col md:flex-row items-start md:items-center gap-x-12 w-full">
            <InputComponent
              name="maleStudents"
              label={t("label.maleStudents")}
              type="number"
              id="male_students"
              placeholder={t("placeholder.number")}
              value={formik.values.maleStudents}
              onBlur={formik.onBlur}
              error={formik.touched.maleStudents && formik.errors.maleStudents}
              onChange={formik.handleChange}
            />
            <InputComponent
              name="femaleStudents"
              label={t("label.femaleStudents")}
              type="number"
              id="female_students"
              placeholder={t("placeholder.number")}
              value={formik.values.femaleStudents}
              onBlur={formik.onBlur}
              error={
                formik.touched.femaleStudents && formik.errors.femaleStudents
              }
              onChange={formik.handleChange}
            />
            <div>
              <LabelComponent
                id="number_of_students"
                label={t("label.totalStudents")}
              />
              <span className="font-bold ml-6">
                {sumOfNumbers(
                  formik.values.maleStudents,
                  formik.values.femaleStudents
                )}
              </span>
            </div>
          </div>

          <div className="flex flex-wrap justify-start flex-col md:flex-row items-start md:items-center gap-x-12 w-full">
            <InputComponent
              name="maleSupervisors"
              label={t("label.maleSupervisors")}
              type="number"
              id="male_supervisors"
              placeholder={t("placeholder.number")}
              value={formik.values.maleSupervisors}
              onBlur={formik.onBlur}
              error={
                formik.touched.maleSupervisors && formik.errors.maleSupervisors
              }
              onChange={formik.handleChange}
            />
            <InputComponent
              name="femaleSupervisors"
              label={t("label.femaleSupervisors")}
              type="number"
              id="female_supervisors"
              placeholder={t("placeholder.number")}
              value={formik.values.femaleSupervisors}
              onBlur={formik.onBlur}
              error={
                formik.touched.femaleSupervisors &&
                formik.errors.femaleSupervisors
              }
              onChange={formik.handleChange}
            />
            <div>
              <LabelComponent
                id="number_of_supervisors"
                label={t("label.totalSupervisors")}
              />
              <span className="font-bold ml-6">
                {sumOfNumbers(
                  formik.values.maleSupervisors,
                  formik.values.femaleSupervisors
                )}
              </span>
            </div>
          </div>

          {formik.errors?.ratioError && (
            <RatioAlert
              selectedSchool={formik.values.schoolSection}
              totalStudents={sumOfNumbers(
                formik.values?.maleStudents,
                formik.values?.femaleStudents
              )}
            />
          )}

          <SchoolBusInput
            id="bus_size"
            type="number"
            name="buses"
            formik={formik}
            label={t("label.buses")}
            placeholder={t("placeholder.busSize")}
            buses={formik.values.buses}
            onBlur={formik.onBlur}
            error={formik.touched.buses && formik.errors.buses}
          />
        </div>

        <div className="py-4">
          <div className="pl-8">
            <RadioInput
              name={"type"}
              label={t("label.type")}
              radioLabel1={t("label.free")}
              radioLabel2={t("label.fee")}
              value={formik.values.type}
              onBlur={formik.onBlur}
              error={formik.touched.type && formik.errors.type}
              onChange={(value) => formik.setFieldValue("type", value)}
            />
          </div>
          <div className="bg-[#F9F9FB] pl-8">
            {formik.values?.type === "1" && (
              <div className="py-4 w-full">
                <NatureOfFee
                  formik={formik}
                  buses={formik.values.buses}
                  natureOfFee={formik.values.natureOfFee}
                />
              </div>
            )}
            <div className="py-2 max-w-max">
              <UploadFile
                label={t("label.attachQuotation")}
                buttonLabel={t("label.uploadFileQuotation")}
                id="qoutationDocument"
                file={formik.values.qoutationDocument}
                onBlur={formik.onBlur}
                error={
                  formik.touched.qoutationDocument &&
                  formik.errors.qoutationDocument
                }
                setFile={(file) =>
                  formik.setFieldValue("qoutationDocument", file)
                }
              />
            </div>
          </div>
        </div>

        <div className="pl-8 py-4">
          <div>
            <LabelComponent
              label={t("label.goal")}
              id="message"
              error={formik.touched.goal && formik.errors.goal}
            />
            <textarea
              name="goal"
              id="message"
              rows="4"
              className="w-full mt-2 sm:w-[60%] block p-2.5 text-sm text-[#333333] bg-white border border-[#4A4A4A] rounded-lg  focus:ring-black focus:border-black"
              value={formik.values.goal}
              onBlur={formik.onBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div className="flex flex-col gap-2 py-4">
            <TermsAndConditions
              name="checkOne"
              text={t("label.checkOne")}
              value={formik.values.checkOne}
              onBlur={formik.onBlur}
              error={formik.touched.checkOne && formik.errors.checkOne}
              onChange={formik.handleChange}
            />
            <TermsAndConditions
              name="checkTwo"
              text={t("label.checkTwo")}
              value={formik.values.checkTwo}
              onBlur={formik.onBlur}
              error={formik.touched.checkTwo && formik.errors.checkTwo}
              onChange={formik.handleChange}
            />
            <TermsAndConditions
              name="checkThree"
              text={t("label.checkThree")}
              value={formik.values.checkThree}
              onBlur={formik.onBlur}
              error={formik.touched.checkThree && formik.errors.checkThree}
              onChange={formik.handleChange}
            />
            <TermsAndConditions
              name="checkFour"
              text={t("label.checkFour")}
              value={formik.values.checkFour}
              onBlur={formik.onBlur}
              error={formik.touched.checkFour && formik.errors.checkFour}
              onChange={formik.handleChange}
            />
            <TermsAndConditions
              name="checkFive"
              text={t("label.checkFive")}
              value={formik.values.checkFive}
              onBlur={formik.onBlur}
              error={formik.touched.checkFive && formik.errors.checkFive}
              onChange={formik.handleChange}
            />
          </div>
          <div className="py-4 max-w-max">
            <UploadFile
              label={t("label.signature")}
              buttonLabel={t("label.uploadFilePrincipal")}
              id="principalSignature"
              file={formik.values.principalSignature}
              onBlur={formik.onBlur}
              error={
                formik.touched.principalSignature &&
                formik.errors.principalSignature
              }
              setFile={(file) =>
                formik.setFieldValue("principalSignature", file)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TripForm;
