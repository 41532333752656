import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { optimizeNumber } from "../utils/helpers";
import LabelComponent from "./label/LabelComponent";

function NatureOfFee({ formik, buses, natureOfFee }) {
  const { t } = useTranslation();
  const busesTotalQR = useMemo(() => {
    let total = 0;
    // Check if natureOfFee.ticket.checked is true
    if (natureOfFee.ticket.checked)
      total += parseFloat(natureOfFee.ticket.QR) || 0;
    // Check if natureOfFee.meal.checked is true
    if (natureOfFee.meal.checked) total += parseFloat(natureOfFee.meal.QR) || 0;
    // Add buses.QR to the total
    buses.forEach((bus) => (total += parseFloat(bus.QR) || 0));
    localStorage.setItem("totalCost", total);
    return total;
  }, [buses, natureOfFee]);

  return (
    <div className="flex flex-col items-start sm:flex-row my-4">
      <LabelComponent label={t("label.natureOfFee")} id="fee-nature" />

      <div className="w-full flex flex-col md:flex-row gap-y-6 gap-x-20">
        <div className="flex flex-wrap items-center gap-y-6 gap-x-14">
          {buses.map((bus, index) => (
            <div key={index} className="flex items-center gap-x-4">
              <input
                disabled
                checked
                id="link-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 accent-[#333333] focus:ring-black focus:border-black"
              />
              <span>
                {t("label.busCount")} {optimizeNumber(index + 1)}
              </span>
              <span className="  border-b border-[#333333] text-center">
                <input
                  value={bus.QR}
                  className="w-12 outline-none bg-inherit"
                  type="number"
                  placeholder="0"
                  onChange={(e) => {
                    const clone = [...buses];
                    clone[index] = { ...clone[index], QR: e.target.value };
                    formik.setFieldValue("buses", clone);
                  }}
                />
              </span>
              <span>{t("label.currency")}</span>
            </div>
          ))}
          <div className="flex items-center gap-x-4">
            <input
              id="link-checkbox"
              type="checkbox"
              checked={natureOfFee.meal.checked}
              className="w-4 h-4 accent-[#333333] focus:ring-black focus:border-black"
              onChange={() =>
                formik.setFieldValue(
                  "natureOfFee.meal.checked",
                  !natureOfFee.meal.checked
                )
              }
            />
            <span>{t("label.meal")}</span>
            <span className="border-b border-[#333333] text-center">
              <input
                value={natureOfFee.meal.QR}
                className="w-12 outline-none bg-inherit"
                type="number"
                placeholder="0"
                onChange={(e) =>
                  formik.setFieldValue("natureOfFee.meal.QR", e.target.value)
                }
              />
            </span>
            <span>{t("label.currency")}</span>
          </div>
          <div className="flex items-center gap-x-4">
            <input
              id="link-checkbox"
              type="checkbox"
              checked={natureOfFee.ticket.checked}
              className="w-4 h-4 accent-[#333333] focus:ring-black focus:border-black"
              onChange={() =>
                formik.setFieldValue(
                  "natureOfFee.ticket.checked",
                  !natureOfFee.ticket.checked
                )
              }
            />
            <span>{t("label.ticket")}</span>
            <span className="  border-b border-[#333333] text-center">
              <input
                value={natureOfFee.ticket.QR}
                className="w-12 outline-none bg-inherit"
                type="number"
                placeholder="0"
                onChange={(e) =>
                  formik.setFieldValue("natureOfFee.ticket.QR", e.target.value)
                }
              />
            </span>{" "}
            <span>{t("label.currency")}</span>
          </div>
        </div>
        <div className="border-r border-[#7E7E7E]"></div>
        <div className="min-w-[200px] whitespace-nowrap">
          <span>{t("label.totalFee")}</span>
          <span className="font-semibold ml-2">
            {busesTotalQR} {t("label.currency")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default NatureOfFee;
