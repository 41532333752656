import React from "react";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import { NavLink } from "react-router-dom";
import SuccessAlert from "./alert/SuccessAlert";
import LoaderComponent from "./loader/LoaderComponent";

const Header = ({ loader, formik, formSubmitted, insideQatar }) => {
  const { t } = useTranslation();
  return (
    <div className="sticky top-0 w-full bg-white border-b-[#F9F9FB] border-b-2 z-20">
      <header className="container m-auto ">
        <nav className="bg-white border-gray-200 px-5 py-2.5">
          <div className="flex flex-wrap gap-4 justify-between items-center min-h-[8vh]">
            <NavLink to="/" className="flex items-center">
              <img
                src="images/logo.png"
                className="mr-3 h-6 sm:h-9"
                alt="Logo"
              />
              <span className="self-center text-xl font-semibold whitespace-nowrap">
                {insideQatar
                  ? t("header.insideQatar")
                  : t("header.outsideQatar")}
              </span>
            </NavLink>
            <div className="flex items-center">
              <button
                type="reset"
                onClick={formik.handleReset}
                className="text-black bg-[#C5C5C5]  hover:bg-[#C5C5C5] font-medium rounded-md text-sm px-4 py-2 me-2 w-[80px]">
                {t("button.cancel")}
              </button>
              <button
                disabled={loader}
                type="submit"
                className="disabled:opacity-40 text-white bg-[#333333] hover:bg-[#333333] font-medium rounded-md text-sm px-4 py-2 me-2 w-[80px]">
                {loader ? <LoaderComponent /> : t("button.submit")}
              </button>
              <LanguageSelector formik={formik} />
            </div>
          </div>
        </nav>
      </header>
      {formSubmitted && <SuccessAlert />}
    </div>
  );
};

export default Header;
