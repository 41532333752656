import { schoolSectionEnum } from "./constants";

const optimizeNumber = (value) => {
  if (value) {
    return value.toString().padStart(2, 0);
  } else {
    return "00";
  }
};

const tripFormPayload = (value, insideQatar) => {
  const is_paid = value.type === "1";
  const formData = new FormData();
  formData.append("school_id", value.school?.id);
  formData.append("principal_name", value.principal);
  formData.append("title", value.title);
  formData.append("trip_date", value.date);
  formData.append("trip_nature", value.nature);
  formData.append("trip_purpose", value.purpose?.value);
  formData.append(
    "school_section",
    schoolSectionEnum[value.schoolSection] || ""
  );
  formData.append(
    "male_students_count",
    value.maleStudents ? value.maleStudents : "0"
  );
  formData.append(
    "female_students_count",
    value.femaleStudents ? value.femaleStudents : "0"
  );
  formData.append(
    "male_supervisor_count",
    value.maleSupervisors ? value.maleSupervisors : "0"
  );
  formData.append(
    "female_supervisor_count",
    value.femaleSupervisors ? value.femaleSupervisors : "0"
  );
  formData.append("is_paid", is_paid);
  if (is_paid) {
    if (value.natureOfFee.meal.checked)
      formData.append("meal", value.natureOfFee.meal?.QR);
    else formData.append("meal", "0");
    if (value.natureOfFee.ticket.checked)
      formData.append("ticket", value.natureOfFee.ticket?.QR);
    else formData.append("ticket", "0");
    formData.append("total", localStorage.getItem("totalCost"));
  }
  formData.append("goals", value.goal);
  formData.append("has_accurate_information", value.checkOne);
  formData.append("has_first_aid_incharge", value.checkTwo);
  formData.append("has_first_aid_kits", value.checkThree);
  formData.append("has_principal_trip_responsibility", value.checkFour);
  formData.append("has_principal_ethics_responsibility", value.checkFive);
  formData.append("document", value.qoutationDocument);
  formData.append("signature", value.principalSignature);
  formData.append("is_inside", insideQatar);
  if (insideQatar) formData.append("location_id", value.location?.id);
  else formData.append("location_details", value.location?.description);
  formData.append(
    "buses",
    JSON.stringify(
      value.buses.map((item, index) => ({
        bus_name: `Bus ${index + 1}`,
        seats: item?.value ? item?.value : "0",
        price_per_seat: item?.QR ? item?.QR : "0",
      }))
    )
  );

  return formData;
};

const errorMessageHandler = (responseData) => {
  let errorArray = [];
  const defaultMessage = "Something went wrong!";
  if (responseData?.errors?.length) {
    responseData.errors.map((e) =>
      errorArray.push(`${e.field} ${e.message}` ?? defaultMessage)
    );
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push(defaultMessage);
  }
  return errorArray[0];
};

const sumOfNumbers = (num1, num2) => {
  if (num1 && num2) {
    return parseInt(num1) + parseInt(num2);
  } else {
    return num1 || num2 || 0;
  }
};

export { optimizeNumber, sumOfNumbers, tripFormPayload, errorMessageHandler };
