import React from "react";
import { NavLink } from "react-router-dom";

function LinkCards({ to, title }) {
  return (
    <NavLink
      to={to}
      className="w-full h-48 rounded-md flex items-center justify-center bg-gray-800"
    >
      <div
        className="text-gray-200 no-underline p-4 text-secondary"
        style={{ textDecoration: "none" }}
      >
        {title}
      </div>
    </NavLink>
  );
}

export default LinkCards;
