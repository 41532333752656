import React from "react";
import LabelComponent from "./label/LabelComponent";
import { useTranslation } from "react-i18next";

function SchoolSection({ school, label, id, name, onChange, value, error }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-start sm:flex-row sm:items-center my-4">
      <LabelComponent label={label} id={id} error={error} />
      <div className="w-full flex-wrap flex items-center gap-x-20 gap-y-4">
        <div className="flex items-center">
          <input
            id="kindergarden"
            name={name}
            type="radio"
            checked={value === "1" ? true : false}
            onChange={() => onChange("1")}
            className="w-4 h-4 accent-[#333333] focus:ring-black focus:border-black"
          />
          <LabelComponent
            label={t("label.kindergarden")}
            id="kindergarden"
            styles="ms-2 !min-w-0"
          />
        </div>
        {school?.kind?.toLowerCase() !== "kindergarten" && (
          <>
            <div className="flex items-center">
              <input
                id="primary"
                name={name}
                type="radio"
                checked={value === "2" ? true : false}
                onChange={() => onChange("2")}
                className="w-4 h-4 accent-[#333333] focus:ring-black focus:border-black"
              />
              <LabelComponent
                label={t("label.primary")}
                id="primary"
                styles="ms-2 !min-w-0"
              />
            </div>
            <div className="flex items-center">
              <input
                id="preparatory"
                name={name}
                type="radio"
                checked={value === "3" ? true : false}
                onChange={() => onChange("3")}
                className="w-4 h-4 accent-[#333333] focus:ring-black focus:border-black"
              />
              <LabelComponent
                label={t("label.preparatory")}
                id="preparatory"
                styles="ms-2 !min-w-0"
              />
            </div>
            <div className="flex items-center">
              <input
                id="secondary"
                name={name}
                type="radio"
                checked={value === "4" ? true : false}
                onChange={() => onChange("4")}
                className="w-4 h-4 accent-[#333333] focus:ring-black focus:border-black"
              />
              <LabelComponent
                label={t("label.secondary")}
                id="secondary"
                styles="ms-2 !min-w-0"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SchoolSection;
