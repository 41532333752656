import { useTranslation } from "react-i18next";
import AutoCompleteComponent from "./autoComplete/AutoCompleteComponent";
import LabelComponent from "./label/LabelComponent";
import Mailto from "./mailto/Mailto";

export default function SelectSchool({
  formik,
  placeholder,
  error,
  options,
  name,
  id,
}) {
  const { t } = useTranslation();
  const getSelectedSchoolInfo = () => {
    const selectedSchool = formik.values.school;
    if (selectedSchool) {
      const selectedOption = options.find(
        (item) => item.id === selectedSchool.id
      );
      return selectedOption;
    }
    return null;
  };

  const selectedSchoolInfo = getSelectedSchoolInfo();
  return (
    <div className="py-4 bg-[#F9F9FB]">
      <div className="flex w-full  flex-col items-start sm:flex-row sm:items-center mx-auto px-8 container">
        <LabelComponent
          label={t("label.school")}
          id={"school-label"}
          error={error && !formik.values.school}
        />
        <div className="flex flex-row items-center w-full sm:w-1/2">
          <AutoCompleteComponent
            name={name}
            id={id}
            placeholder={placeholder}
            options={options}
            value={formik.values.school}
            onChange={(e) => {
              formik.setFieldValue("school", e);
              if (e.kind?.toLowerCase() === "kindergarten")
                formik.setFieldValue("schoolSection", "1");
              else formik.setFieldValue("schoolSection", "2");
            }}
            language={formik.values.language}
          />
          {/* <button
            type="submit"
            className="px-4 py-2.5 text-sm font-medium h-full text-white bg-[#333333] rounded-e-md border border-[#333333] "
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button> */}
        </div>
      </div>
      {selectedSchoolInfo && (
        <div className="px-8 py-4 mt-4 bg-[#F9F9FB] container mx-auto">
          <div className="flex justify-around container mx-auto sm:px-8 flex-col lg:flex-row max-w-5xl mr-0 lg:ml-20">
            <div>
              <span className="text-sm">{t("school.consultant")}</span>
              <div className="mt-1">{selectedSchoolInfo.specialist}</div>
            </div>
            <div className="border-r-2 border-black hidden lg:block"></div>
            <div>
              <span className="text-sm">{t("school.location")}</span>
              <div className="mt-1">{selectedSchoolInfo.location}</div>
            </div>
            <div>
              <span className="text-sm"> {t("school.phoneNumber")}</span>
              {selectedSchoolInfo.phone?.split(",").map((number, index) => (
                <div key={index} className="mt-1">
                  {number}
                </div>
              ))}
            </div>
            <div>
              <span className="text-sm">{t("school.email")}</span>
              {selectedSchoolInfo.email ? (
                <Mailto email={selectedSchoolInfo.email}>
                  <div className="mt-1">{selectedSchoolInfo.email}</div>
                </Mailto>
              ) : (
                <div className="mt-1">-</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
