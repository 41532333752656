import { schoolSectionEnum } from "../../utils/constants";

const RatioAlert = ({ selectedSchool, totalStudents }) => {
  let supervisorsRatio = 1;

  const parsedSelectedSchool = parseInt(selectedSchool);

  if (parsedSelectedSchool === 1 || parsedSelectedSchool === 2) {
    supervisorsRatio = totalStudents / 10;
  } else if (parsedSelectedSchool === 3 || parsedSelectedSchool === 4) {
    supervisorsRatio = totalStudents / 13;
  }

  return (
    totalStudents > 0 && (
      <div className="py-4 w-full md:w-8/12">
        <div className="flex items-center p-4 rounded-lg bg-red-50 border border-red-500">
          <i className="fa-solid fa-circle-info"></i>
          <div className="ms-3 text-sm font-medium text-[#181818]">
            {`For ${
              schoolSectionEnum[selectedSchool]
            } schools,  The number of supervisors allowed for ${totalStudents} students is limited to ${Math.ceil(
              supervisorsRatio
            )}`}
          </div>
        </div>
      </div>
    )
  );
};

export default RatioAlert;
