import React from "react";
import LabelComponent from "./label/LabelComponent";

function RadioInput({
  label,
  name,
  id1,
  id2,
  radioLabel1,
  radioLabel2,
  onChange,
  value,
  error,
}) {
  return (
    <div className="flex flex-col items-start sm:flex-row sm:items-center my-4">
      <LabelComponent label={label} id={id1} error={error} />
      <div className="w-full flex items-center space-x-10">
        <div className="flex items-center">
          <input
            id={id1}
            name={name}
            type="radio"
            checked={value === "0" ? true : false}
            onChange={() => onChange("0")}
            className="w-4 h-4 accent-[#333333] focus:ring-black focus:border-black"
          />
          <LabelComponent label={radioLabel1} id={id1} styles="ms-2 !min-w-0" />
        </div>
        <div className="flex items-center">
          <input
            id={id2}
            name={name}
            type="radio"
            checked={value === "1" ? true : false}
            onChange={() => onChange("1")}
            className="w-4 h-4 accent-[#333333] focus:ring-black focus:border-black"
          />
          <LabelComponent label={radioLabel2} id={id2} styles="ms-2 !min-w-0" />
        </div>
      </div>
    </div>
  );
}

export default RadioInput;
