import React from "react";
import LinkCard from "./LinkCard";
import { useTranslation } from "react-i18next";

const Main = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="px-8 w-full md:w-8/12 lg:w-6/12 flex items-center justify-center">
        <div className="w-full space-y-2">
          <img
            className="h-15 w-15 md:h-18 md:w-18"
            alt="Logo"
            src="images/logo.png"
          />
          <h2 className="text-lg md:text-2xl font-bold">{t("main.title")}</h2>
          <div className="flex flex-col md:flex-row gap-4">
            <LinkCard to="outside-qatar" title={t("main.outside")} />
            <LinkCard to="inside-qatar" title={t("main.inside")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
