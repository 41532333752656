import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Page404() {
  const { t } = useTranslation();

  return (
    <section className="bg-white min-h-[100vh] flex items-center justify-center">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-[#333333]">
            404
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-[#333333] md:text-4xl">
            {t("404Error.title")}
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 ">
            {t("404Error.subtitle")}
          </p>
          <Link to="/">
            <button
              type="button"
              className=" text-white bg-[#333333] hover:bg-[#333333] font-medium rounded-md text-sm px-4 py-2">
              {t("button.goHome")}
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Page404;
